import React from "react"
import Base from "./Base"

import LargeGroupEnquiry from "../LargeGroupEnquiry/LargeGroupEnquiry";

const LargeGroupEnquiryPage = ({ pageContext }) =>  {
    
  return (
    <Base context={pageContext}>  
      <LargeGroupEnquiry data={pageContext} />
    </Base>
  )
}

export default LargeGroupEnquiryPage
